<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Quantity</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form class="pt-4">
            <v-autocomplete
              v-model="formData.qty_type"
              label="Quantity type"
              item-text="text"
              item-value="index"
              clearable
              outlined
              :items="qty_types"
              hide-selected
              dense
              :error-messages="qty_typeErrors"
              @input="$v.formData.qty_type.$touch()"
              @blur="$v.formData.qty_type.$touch()"
            ></v-autocomplete>
            <v-text-field
              v-model="formData.qty"
              label="Quantity"
              type="Number"
              clearable
              outlined
              dense
              :error-messages="qtyErrors"
              @change="() => validateMinValue('qty', 0)"
              @input="$v.formData.qty.$touch()"
              @blur="$v.formData.qty.$touch()"
            ></v-text-field>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditQty",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      qty_type: { required },
      qty: { required },
    },
  },
  props: ["item", "actionFunction", "qty_types",],
  data: () => ({
    dialog: false,
    formData: {
      qty_type: null,
      qty: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.actionFunction(this.formData, this.item);
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      if (this.item) {
        this.formData = JSON.parse(JSON.stringify(this.item));
      } else {
        this.formData = {
          qty_type: null,
          qty: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    qty_typeErrors: function () {
      return this.handleFormValidation("qty_type", this);
    },
    qtyErrors: function () {
      return this.handleFormValidation("qty", this);
    },
  },
};
</script>
