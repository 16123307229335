<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    width="1000"
    v-if="!!serverData"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Billing details</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-4 mt-4">
        <v-row>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.number_of_pallets_used"
              label="Number of pallets used"
              type="Number"
              clearable
              outlined
              dense
              @change="() => validateMinValue('value', 0)"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-autocomplete
              v-model="formData.delivery_vehicle_id"
              label="Delivery vehicle"
              item-text="text"
              item-value="index"
              clearable
              outlined
              :items="serverData.delivery_vehicles"
              hide-selected
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6">
            <v-autocomplete
              v-model="formData.delivery_vehicle_storage_type_id"
              label="Delivery vehicle storage type"
              item-text="text"
              item-value="index"
              clearable
              outlined
              :items="serverData.delivery_vehicle_storage_types"
              hide-selected
              dense
              :error-messages="delivery_vehicle_storage_type_idErrors"
              @input="$v.formData.delivery_vehicle_storage_type_id.$touch()"
              @blur="$v.formData.delivery_vehicle_storage_type_id.$touch()"
            ></v-autocomplete>
          </div>
        </v-row>
        <v-col cols="12 pa-0 ma-0">
          <qty-datatable
            :setSku="setQtys"
            :skus="formData.additional_service_quantities"
            :qty_types="serverData.qty_types"
          />
        </v-col>

        <!--begin::Actions-->
        <div class="mb-4 mt-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click.prevent="resetEditForm"
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
          >
            Clear
          </button>
          <button
            @click.prevent="submitEditForm"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import QtyDatatable from "@/own/components/fulfillment/orders/qtyContainer/QtyDatatable";
import validationMixin from "@/own/mixins/validationMixin";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "BillingDetails",
  props: ["refresher", "pageLoader", "itemForAction"],
  mixins: [validationMixin, fieldValueValidation],
  components: { QtyDatatable },
  validations: {
    formData: {
      delivery_vehicle_storage_type_id: {
        required: requiredIf(function () {
          return this.formData.delivery_vehicle_id;
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      additional_service_quantities: [],
      number_of_pallets_used: null,
      delivery_vehicle_id: null,
      delivery_vehicle_storage_type_id: null,
    },
  }),
  methods: {
    setQtys(items) {
      this.formData.additional_service_quantities = JSON.parse(
        JSON.stringify(items)
      );
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      ApiService.post(
        "/fulfillment/orders/update_order_additional_service_quantities",
        data
      )
        .then((response) => {
          this.serverData = response.data;
          this.formData = JSON.parse(JSON.stringify(response.data.order));
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      ApiService.post(
        "/fulfillment/orders/update_order_additional_service_quantities",
        this.formData
      )
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        this.formData = JSON.parse(JSON.stringify(this.serverData.order));
      } else {
        this.formData = {
          id: null,
          additional_service_quantities: [],
          number_of_pallets_used: null,
          delivery_vehicle_id: null,
          delivery_vehicle_storage_type_id: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    delivery_vehicle_storage_type_idErrors() {
      return this.handleFormValidation(
        "delivery_vehicle_storage_type_id",
        this
      );
    },
  },
};
</script>
