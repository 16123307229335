<template>
  <div>
    <v-data-table :headers="headers" :items="skus" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Quantities</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-info px-5 py-3 ls1 mb-2"
            @click="showAddSkuModal()"
          >
            New Item
          </button>
        </v-toolbar>
      </template>
      <template v-slot:item.qty_type="{item}">
        {{qty_types.find(qty => qty.index == item.qty_type)?.text}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditQty
      ref="editSku"
      :actionFunction="editSkus"
      :qty_types="qty_types"
      :item="getItemForAction"
    ></EditQty>
  </div>
</template>

<script>
import EditQty from "@/own/components/fulfillment/orders/qtyContainer/EditQty";
import Swal from "sweetalert2";
export default {
  name: "QtyDatatable",
  props: ["setSku", "skus", "qty_types"],
  components: { EditQty },
  data: () => ({
    dialog: false,
    headers: [
      { text: "Quantity type", value: "qty_type" },
      { text: "Quantity", value: "qty" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemForAction: null,
  }),
  computed:{
    getItemForAction(){
      return this.itemForAction;
    }
  },
  methods: {
    showEditSkuModal(item) {
      this.itemForAction = JSON.parse(JSON.stringify(item));
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    showAddSkuModal() {
      this.itemForAction = null;
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    editSkus(item, oldItem = false) {
      let new_skus = JSON.parse(JSON.stringify(this.skus));
      if (oldItem) {
        let index = this.skus.findIndex(
          (sku) => sku.qty_type == oldItem.qty_type
        );
        if (index > -1) {
          new_skus.splice(index, 1);
        }
      }
      let copy_index = new_skus.findIndex(
        (sku) => sku.qty_type == item.qty_type
      );
      if (copy_index > -1) {
        new_skus[copy_index] = item;
      } else {
        new_skus.push(item);
      }
      this.setSku(new_skus);
    },
    removeSkus(item) {
      let new_skus = JSON.parse(JSON.stringify(this.skus));
      let index = new_skus.findIndex(
        (sku) => sku.qty_type == item.qty_type && sku.qty == item.qty
      );
      if (index > -1) {
        new_skus.splice(index, 1);
        this.setSku(new_skus);
      } else {
        Swal.fire({
          title: "Error",
          text: `Something went wrong!`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
};
</script>
